var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":"","disabled":_vm.isTooltipStyleActivated},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"hh-slot-amount-indicator d-flex px-4 py-2",class:{
                    'has-error': _vm.tooMuch,
                    'is-perfect': _vm.perfect,
                     'is-tooltip-style-activated': _vm.isTooltipStyleActivated
                }},on),[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":_vm.iconColor}},[_vm._v("fas fa-truck")]),_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":_vm.iconColor}},[_vm._v("fas fa-tractor")]),_c('v-icon',{attrs:{"size":"20","color":_vm.iconColor}},[_vm._v("fas fa-box")]),_c('span',{staticClass:"ml-4 font-weight-bold text-no-wrap",class:{
                    'error--text': _vm.tooMuch,
                    'success--text': _vm.perfect,
                    'primary--text' : !_vm.isTooltipStyleActivated,
                    'white--text' : _vm.isTooltipStyleActivated,
                }},[_vm._v(" "+_vm._s(_vm.amount)+" "),(_vm.requiredAmount !== null)?_c('span',[_vm._v(" / "+_vm._s(_vm.requiredAmount)+" ")]):_vm._e()])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('CALENDAR.BOOKED_HH_HINT')))])])}
var staticRenderFns = []

export { render, staticRenderFns }